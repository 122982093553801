
import React, { useState, useEffect } from 'react';
import './menu.css'
import {
  HomeOutlined,
  StarOutlined,
  TeamOutlined,
  DesktopOutlined,
  EyeOutlined,
  AppleOutlined,
  DollarOutlined,
  FlagOutlined,
  UserOutlined,
  NotificationOutlined ,
  ApartmentOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  MonitorOutlined,
  ControlOutlined,
  WindowsOutlined,
  MailOutlined,
  ReadOutlined,
  ShopOutlined,
  SafetyOutlined,
  TrademarkOutlined,
  QuestionCircleOutlined,
  ContainerOutlined,
  LockOutlined ,
  BankOutlined,
  IdcardOutlined,
  UngroupOutlined,
  FileDoneOutlined,
  CreditCardOutlined
} from '@ant-design/icons';
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {setUser} from '../../redux/actions/AuthActions'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import {Collapsed} from '../../redux/actions/MenuAction'

const { Sider } = Layout;
const { SubMenu } = Menu;

export const SiderMenu = ({isRTL,currentUser,setUser,ChangeLanguage,Collapsed,menu}) => {
  const [collapsed, setCollapsed] = useState(menu);
  const location = useLocation();
console.log(collapsed)

  if(isRTL){
    allStrings.setLanguage('ar')
  }else{
    allStrings.setLanguage('en')
  } 
  

  useEffect(() => {
    if(isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  });
  const onCollapse = (collapsed) =>{ setCollapsed(collapsed);Collapsed(collapsed);localStorage.setItem("@MENU",collapsed)};
  return (
    <Sider 
    style={{
      overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left:0
        //left: isRTL?'auto':0,
        //right: isRTL?0:'auto',
        //direction:isRTL?'rtl':'ltr'
    }}
    collapsible collapsed={collapsed} onCollapse={onCollapse} >
      {!collapsed?
      <div className="logo" >
        <img alt="" style={{width: '60%',position: 'relative',top:isRTL?'-6px':'-6px',left:'30px'}} src={require('../../assets/images/logo.png')} ></img>
        <p style={{letterSpacing:'6px',color:'#fff',fontSize:isRTL?'34px':'26px',fontStyle: 'oblique',
            fontWeight: '800',fontFamily: 'fantasy',display:'inline-block',marginLeft:isRTL?'10px':'0px',marginTop:isRTL?'-10px': 0}}></p>
      </div>
      :
      <div className="logo" >
        <img alt="" style={{width: '95%',position: 'relative',top: '2px',left:'0px'}} src={require('../../assets/images/logo.png')} ></img>       
      </div>      
      }
      
      
      
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        
         {/*Home */}
         {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Home">
              <HomeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.home}</span>
              <Link to="/Home"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('DASHBOARD')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Home">
              <HomeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.home}</span>
              <Link to="/Home"></Link>
            </Menu.Item>
          }
        
        <SubMenu  key="sub2" icon={<UserOutlined style={{/*
            float:isRTL? 'right':'none',
            position:isRTL?'absolute':'relative',
            right:isRTL?collapsed?28:45:'auto'*/
            }} />} title={allStrings.users}>
            {/*CLIENTS */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CLIENTS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Users" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{marginRight:isRTL? '6px':'0px'}}>{allStrings.users}</span>
              <Link to="/Users"></Link>
            </Menu.Item>
            }
            {/*OFFICES */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Offices" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Offices}</span>
              <Link to="/Offices"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('OFFICES')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Offices" > 
            <UserOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Offices}</span>
              <Link to="/Offices"></Link>
            </Menu.Item>
            }
            {/*SUB-ADMIN */}
            {currentUser.user.type ==="ADMIN" ?
            
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Supervisors" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.supervisors}</span>
              <Link to="/Supervisors"></Link>
            </Menu.Item>
              :
              currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SUB-ADMIN')&&
              
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Supervisors" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.supervisors}</span>
              <Link to="/Supervisors"></Link>
            </Menu.Item>
            }
          
          {currentUser.user.type ==="ADMIN" &&
          <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Admins" > 
          <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
              <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.admin}</span>
            <Link to="/Admins"></Link>
          </Menu.Item>
          }
          
          {/*SUB-ADMIN */}
          {currentUser.user.type ==="ADMIN" ?
            
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/addUser" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.AddUser}</span>
              <Link to="/addUser"></Link>
            </Menu.Item>
              :
              currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ADD-USER')&&
              
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/addUser" > 
            <TeamOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.AddUser}</span>
              <Link to="/addUser"></Link>
            </Menu.Item>
            }
        </SubMenu>

        {/*categories */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
         <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
           <Link to="/Categories"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CATEGORIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Categories"> 
        <ApartmentOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.categories}</span>
          <Link to="/Categories"></Link>
        </Menu.Item>
        }

        

        
         {/*anoncements */}
         {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
         <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
           <Link to="/anoncements"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ANONCEMENTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/anoncements"> 
        <DesktopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.anoncements}</span>
          <Link to="/anoncements"></Link>
        </Menu.Item>
        }
         {/*ads */}
         {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Ads"> 
         <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.ads}</span>
           <Link to="/Ads"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ADS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Ads"> 
        <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.ads}</span>
          <Link to="/Ads"></Link>
        </Menu.Item>
        }
        {/*ads */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/marketAds"> 
         <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.marketAds}</span>
           <Link to="/marketAds"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ADS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/marketAds"> 
        <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.marketAds}</span>
          <Link to="/marketAds"></Link>
        </Menu.Item>
        }
         {/*RedfAds */}
         {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/RedfAds"> 
         <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.RedfAds}</span>
           <Link to="/RedfAds"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ADS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/RedfAds"> 
        <WindowsOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.RedfAds}</span>
          <Link to="/RedfAds"></Link>
        </Menu.Item>
        }
        {/*REQUESTS */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Requests"> 
        <TrademarkOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.requests}</span>
          <Link to="/Requests"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('REQUESTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Requests"> 
        <TrademarkOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.requests}</span>
          <Link to="/Requests"></Link>
        </Menu.Item>
        
        }
        {/*Clients */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Clients"> 
         <IdcardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Clients}</span>
           <Link to="/Clients"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CLIENTS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Clients"> 
        <IdcardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Clients}</span>
          <Link to="/Clients"></Link>
        </Menu.Item>
        }
        {/*Companies */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Companies"> 
         <AppleOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Companies}</span>
           <Link to="/Companies"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('COMPANIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Companies"> 
        <AppleOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Companies}</span>
          <Link to="/Companies"></Link>
        </Menu.Item>
        }
        {/*PaymentPlans */}
        {currentUser.user.type ==="ADMIN" ?
         <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/PaymentPlans"> 
         <CreditCardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
           <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.PaymentPlans}</span>
           <Link to="/PaymentPlans"></Link>
         </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('PAYMENT-PLANS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/PaymentPlans"> 
        <CreditCardOutlined  style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.PaymentPlans}</span>
          <Link to="/PaymentPlans"></Link>
        </Menu.Item>
        }
        
        <SubMenu  key="sub20" icon={<ContainerOutlined style={{/*
            float:isRTL? 'right':'none',
            position:isRTL?'absolute':'relative',
            right:isRTL?collapsed?28:45:'auto'*/
            }} />} title={allStrings.archieves}>
            {/*Contact-REQUEST */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactRequests"> 
            <MessageOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactRequests}</span>
              <Link to="/ContactRequests"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CONTACT-REQUEST')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactRequests"> 
            <MessageOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactRequests}</span>
              <Link to="/ContactRequests"></Link>
            </Menu.Item>
            }
            {/*INTERESTS */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Interests"> 
            <FileDoneOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.interests}</span>
              <Link to="/Interests"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('INTERESTS')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Interests"> 
            <FileDoneOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.interests}</span>
              <Link to="/Interests"></Link>
            </Menu.Item>
            }
            {/*Ads-Views */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/adsViews"> 
            <EyeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.views}</span>
              <Link to="/views"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('VIEWS')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/adsViews"> 
            <EyeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.views}</span>
              <Link to="/views"></Link>
            </Menu.Item>
            }
            {/*MarketViews */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/MarketViews"> 
            <EyeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.MarketViews}</span>
              <Link to="/MarketViews"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('VIEWS')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/MarketViews"> 
            <EyeOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.MarketViews}</span>
              <Link to="/MarketViews"></Link>
            </Menu.Item>
            }
            {/*Search-History */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/searchHistory"> 
            <MonitorOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.searchHistory}</span>
              <Link to="/searchHistory"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('VIEWS')&&
            <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/searchHistory"> 
            <MonitorOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
              <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.searchHistory}</span>
              <Link to="/searchHistory"></Link>
            </Menu.Item>
            }
              
        </SubMenu>
        {/*Business */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Business"> 
        <ShopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.business}</span>
          <Link to="/Business"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('BUSINESS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Business"> 
        <ShopOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.business}</span>
          <Link to="/Business"></Link>
        </Menu.Item>
        }
        {/*FEATURES */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Features"> 
        <StarOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Features}</span>
          <Link to="/Features"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('FEATURES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Features"> 
        <StarOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Features}</span>
          <Link to="/Features"></Link>
        </Menu.Item>
        }
        {/*Banks */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Banks"> 
        <BankOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.banks}</span>
          <Link to="/Banks"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('BANKS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Banks"> 
        <BankOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.banks}</span>
          <Link to="/Banks"></Link>
        </Menu.Item>
        }
         {/*Services */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Services"> 
        <UngroupOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.services}</span>
          <Link to="/Services"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SERVICES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Services"> 
        <UngroupOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.services}</span>
          <Link to="/Services"></Link>
        </Menu.Item>
        }
         {/*TRANSACTIONS */}
         {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Transactions"> 
        <DollarOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Transactions}</span>
          <Link to="/Transactions"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('TRANSACTIONS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Transactions"> 
        <DollarOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Transactions}</span>
          <Link to="/Transactions"></Link>
        </Menu.Item>
        }
         {/*COUNTRIES */}
         {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('COUNTRIES')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Countries"> 
        <FlagOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
          <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Countries}</span>
          <Link to="/Countries"></Link>
        </Menu.Item>
        }
        
        
        
        
        

        <SubMenu key="sub3" style={{/*paddingLeft:isRTL?'26px':'0px'*/}} icon={<ControlOutlined style={{/*
            float:isRTL? 'right':'none',
            position:isRTL?'absolute':'relative',
            right:isRTL?collapsed?28:45:'auto'*/
            }}  />} title={allStrings.setting}>
            {/*ABOUT */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
            <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
              <Link to="/about"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('ABOUT')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/about"> 
              <InfoCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                  <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.about}</span>
                <Link to="/about"></Link>
            </Menu.Item>
            }
            {/*SETTINGS */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
            <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
              <Link to="/Setting"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SETTINGS')&&
            <Menu.Item style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Setting"> 
              <ControlOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                  <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.setting}</span>
                <Link to="/Setting"></Link>
            </Menu.Item>
            }
            {/*PERMISSIONS */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Permissions"> 
            <LockOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.permissions}</span>
              <Link to="/Permissions"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('PERMISSIONS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Permissions"> 
              <LockOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}}/>
                  <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.permissions}</span>
                <Link to="/Permissions"></Link>
              </Menu.Item>
            }
            {/*terms */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Terms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Terms}</span>
              <Link to="/Terms"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('SETTINGS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/Terms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.Terms}</span>
              <Link to="/Terms"></Link>
            </Menu.Item>
            }
            {/*PaymentTerms */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/PaymentTerms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.paymentTerms}</span>
              <Link to="/PaymentTerms"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('TERMS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/PaymentTerms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.paymentTerms}</span>
              <Link to="/PaymentTerms"></Link>
            </Menu.Item>
            }
            {/*MarketTerms */}
            {currentUser.user.type ==="ADMIN" ?
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/MarketTerms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.MarketTerms}</span>
              <Link to="/MarketTerms"></Link>
            </Menu.Item>
            :
            currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('TERMS')&&
            <Menu.Item  style={{/*textAlign:isRTL?collapsed?'end': 'start':'start',paddingRight:isRTL?collapsed?'20px':'40px':0,paddingLeft:isRTL?'0px':collapsed?'14px':'48px'*/}} key="/MarketTerms"> 
            <SafetyOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?13:12:collapsed?'0px':'0'*/}} />
                <span className="menuTitle"  style={{/*marginRight:isRTL? '6px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.MarketTerms}</span>
              <Link to="/MarketTerms"></Link>
            </Menu.Item>
            }
        </SubMenu>
        {/*FAQ */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Questions"> 
        <QuestionCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.questions}</span>
          <Link to="/Questions"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('FAQ')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/Questions"> 
        <QuestionCircleOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.questions}</span>
          <Link to="/Questions"></Link>
        </Menu.Item>
        }
        {/*News */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/News"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.news}</span>
          <Link to="/News"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('FAQ')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/News"> 
        <ReadOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.news}</span>
          <Link to="/News"></Link>
        </Menu.Item>
        }
        {/*NOTIFS */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('NOTIFS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendNotifs"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.sendNotif}</span>
          <Link to="/SendNotifs"></Link>
        </Menu.Item>
        }
        {/*SendMarketingNotif */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendMarketingNotif"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.marketingNotif}</span>
          <Link to="/SendMarketingNotif"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('NOTIFS')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/SendMarketingNotif"> 
        <NotificationOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.marketingNotif}</span>
          <Link to="/SendMarketingNotif"></Link>
        </Menu.Item>
        }
        
        {/*CONTACT-US */}
        {currentUser.user.type ==="ADMIN" ?
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactUs"> 
        <MailOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactUs}</span>
          <Link to="/ContactUs"></Link>
        </Menu.Item>
        :
        currentUser.user.type ==="SUB-ADMIN" && currentUser.user.permission.pages.includes('CONTACT-US')&&
        <Menu.Item  style={{/*textAlign:isRTL? 'end':'start'*/}} key="/ContactUs"> 
        <MailOutlined style={{/*position:isRTL?'relative':'relative',float:isRTL? 'right':'none',top:isRTL?collapsed?4:12:collapsed?'0px':'0'*/}}/>
            <span className="menuTitle"  style={{/*marginRight:isRTL? '10px':'0px',marginLeft:isRTL?'0px':'10px',float:isRTL?'right':'none'*/}}>{allStrings.contactUs}</span>
          <Link to="/ContactUs"></Link>
        </Menu.Item>
        }
        
        
        
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        <Menu.Item></Menu.Item>
        
        
      </Menu>
      
    </Sider>
  );
};
const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  menu: state.menu.menu,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  Collapsed
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(SiderMenu)
);


