import React from 'react';
import './home.css'
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";

import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import Skeleton from 'react-loading-skeleton';
import { Line } from "react-chartjs-2";
import moment from 'moment'
import { Spin } from 'antd';
import 'moment/locale/ar';


class Home extends React.Component {
  
  state = {
    count:[],
    tops:[],
    topOfficesRate:[],
    user:[],
    loading:true,
    clients:[],
    graphAds:[],
    days:[],
    daysEN:[],
    daysAR:[],
    maxValue:0,
    graphStep:0,
    requests:[]
   
  }
  
   
  constructor(props){
    super(props)
    this.getCount()
    this.getTopOffices()
    this.getRequests()
    this.getGraph()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 

  }
  getGraph = () => {
    axios.get(`${BASE_END_POINT}admin/getGraph`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log(response.data)
      let daysEN=[]
      let daysAR=[]
      response.data.days.forEach(day => {
        daysAR.push(moment(day).locale('ar').format("ll"))
        daysEN.push(moment(day).locale('en').format('MMM Do YY'))
      });
      let arr = response.data.clients
      let maxValue1 = Math.max( ...arr );

      let max = maxValue1;

      let arr2 = response.data.ads
      let maxValue2 = Math.max( ...arr2 );
      if(maxValue2 > maxValue1)
        max = maxValue2

       
      this.setState({
        loading: false,
        clients:response.data.clients,
        graphAds:response.data.ads,
        daysEN:daysEN,
        daysAR:daysAR,
        maxValue:max,
        graphStep:Math.floor(max / 10)
      })
    })
    .catch(error=>{
      console.log("ALL graph ERROR")
      console.log(error.response)
    })
  }
  getTopOffices = () => {
    axios.get(`${BASE_END_POINT}find?type=OFFICE&sort=rate`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({topOfficesRate:response.data.data})
    })
    .catch(error=>{
      console.log("ALL topOfficesRate ERROR")
      console.log(error.response)
    })
  }
  getCount = () => {
    axios.get(`${BASE_END_POINT}admin/count`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getRequests = () => {
    axios.get(`${BASE_END_POINT}requests?page=1&limit=7`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log(response.data.data)
      this.setState({requests:response.data.data})
    })
    .catch(error=>{
      console.log("ALL requests ERROR")
      console.log(error.response)
    })
  }

  componentDidMount(){
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 
  
  }

  render(){
    const data = {
      labels:this.props.isRTL?this.state.daysAR:this.state.daysEN,
      datasets: [
        {
          label: allStrings.Clients,
          data: this.state.clients,
          fill: true,
          backgroundColor: 'rgba(157, 248, 255, 0.411)',
          borderColor: 'rgba(157, 248, 255, 0.411)',
          pointBorderColor:'rgba(157, 248, 255, 0.411)',
          pointBackgroundColor:'rgba(255, 255,255)',
          pointBorderWidth:5,
          yAxisID: 'y-axis-1',
          
        },
        {
          label: allStrings.ads,
          data:this.state.graphAds,
          fill: true,
          backgroundColor: 'rgba(195, 193, 252, 0.555)', 
          borderColor: 'rgba(54, 162, 235, 0.2)',
          pointBorderColor:'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor:'rgba(255, 255, 255, 1)',
          pointBorderWidth:5,
          yAxisID: 'y-axis-2',
        },
      ],
    }
    
    const options = {
      maintainAspectRatio : false,
      responsive: true,
      legend: {
        display: true,
        position:'bottom',
        align:this.props.isRTL?'end':'start',

        labels: {
            fontColor: '#000',
            borderRadius:'4px',
            boxWidth:20,
            padding:20,
            
        }
      },
      scales: {
        
        yAxes: [
          {
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            id: 'y-axis-1',
            ticks: {
              max: this.state.maxValue,
              min: 0,
              stepSize: this.state.graphStep
            },
            barPercentage: 1,
            offsetGridLines: false,
            gridLines: {
              offsetGridLines: false,
              display: true,
              color: "#F0F2F5",
              borderStyle:'dashed'
            },
          },
          {
            type: 'linear',
            display: false,
            position: 'right',
            id: 'y-axis-2',
            ticks: {
              max: this.state.maxValue,
              min: 0,
              stepSize: this.state.graphStep
            },
            barPercentage: 1,
            offsetGridLines: false,
            gridLines: {
              offsetGridLines: false,
              display: true,
              color: "#F0F2F5",
              borderStyle:'dashed'
            },
            
          },
        ],
      },
    }
    let {loading,topOfficesRate} = this.state;
  return (
    <div className="Home">
      <Spin spinning={this.state.loading} tip={allStrings.loading} size="large" style={{color:'#1D9598'}}>
      <MDBContainer> 
        <MDBRow>
          
         <div style={{textAlign:this.props.isRTL? 'right':'left',
         flexDirection:this.props.isRTL?'row':'row-reverse',
         display:this.props.isRTL?'': 'flex',
         width:this.props.isRTL?'100%': '',padding: '5px 20px 25px'}}>
          <span  style={{
            display: 'inline-block',
            color: '#43425D',
            fontSize:'22px'}}>{allStrings.statistic}</span>
          <span  style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D',margin:'6px',lineHeight:this.props.isRTL?'auto':'15px'}}>|</span>
          <span style={{fontFamily:'system-ui',fontWeight:'bold',fontSize:'22px',display: 'inline-block',
            color: '#43425D'}}>{allStrings.overView}</span>
         </div>
         
        </MDBRow>
        <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>

          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.usersCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart1.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.users}</p>
                  
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.Offices}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart2.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.offices}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.requests}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart3.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.requestsCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.ads}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart4.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.ads}</p>
                   
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.approvedOfficesCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.approvedOfficesCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.notApprovedOfficesCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.notApprovedOfficesCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.completedRequest}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(50%) sepia(13%) saturate(3208%) hue-rotate(179deg) brightness(100%) contrast(80%)'}} src={require('../../assets/images/Bar Chart4.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.completedRequestsCount}</p>
                   
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.failedRequest}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(1%) sepia(142%) saturate(3255%) hue-rotate(33deg) brightness(100%) contrast(341%)'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.failedRequestsCount}</p>
                    
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.onProgressRequest}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(55%) sepia(142%) saturate(3255%) hue-rotate(33deg) brightness(100%) contrast(522%)'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.onProgressRequestsCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.contactRequestCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(10%) sepia(142%) saturate(3255%) hue-rotate(33deg) brightness(100%) contrast(522%)'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.contactRequestCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.paidDepositCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(55%) sepia(142%) saturate(3255%) hue-rotate(0deg) brightness(100%) contrast(522%)'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.paidDepositCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard" style={{textAlign: this.props.isRTL?'right':'left'}}>{allStrings.bookingPaidCount}</p>
                <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse'}}>
                  <MDBCol  md="6" sm="6">
                    <img alt="" style={{width: '100%',marginTop: '20px',filter:'invert(55%) sepia(142%) saturate(3255%) hue-rotate(33deg) brightness(0%) contrast(522%)'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count" style={{textAlign: this.props.isRTL?'right':'left'}}>{loading?<Skeleton height={20} />:this.state.count.bookingPaidCount}</p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
       
      
        <br></br>
        <MDBRow>
          <MDBCol  md="12">
            <p style={{
                fontFamily: 'system-ui',
                background:' #fff',
                marginBottom: '0px',
                padding: '18px 22px',
                fontWeight: '600',
                color: '#43425D',
                fontSize: '17px',
                textAlign: this.props.isRTL?'right':'left'
            }}>{allStrings.statistic}</p>
            <div className="homeChart">
            
            <Line height="400px" data={data} options={options} />
            </div>
          </MDBCol>
        </MDBRow>
        <br></br>
        {/*}
        <MDBRow>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topOfficeRate}</p>
                <MDBRow>
                  <MDBCol xl="4" md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topOfficeRate?this.state.count.topOfficeRate.fullname  :""}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.rate}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topAdsRate}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topAdsRate?this.props.isRTL?this.state.count.topAdsRate.name_ar:this.state.count.topAdsRate.name_en:''}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.rate}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topProductSale}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                  <p className="centerCount">{loading?<Skeleton height={20} />:this.state.count.topProductSale?this.props.isRTL?this.state.count.topProductSale.name_ar:this.state.count.topProductSale.name_en:''}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{allStrings.saleCount}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt="" style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        */}
        <MDBRow style={{flexDirection:this.props.isRTL?'row-reverse':'row'}}>
          <MDBCol xl="8" lg="7" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <p className="topCard" style={{color: '#43425D',textAlign: this.props.isRTL?'right':'left'}}>{allStrings.requests}</p>
              <br></br>
              <table className="table table-borderless">
                <thead className="thead-light">
                  <tr >
                    <th scope="col">{allStrings.owner}</th>
                    <th scope="col">{allStrings.city}</th>
                    <th scope="col">{allStrings.category}</th>
                    <th scope="col">{allStrings.status}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.loading?
                
                <div style={{width:"100%"}} >
                  <Skeleton height={40} /> 
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                </div>
                :
                this.state.requests.length > 0 ?
                  this.state.requests.map(val=>(
                      <tr onClick={()=>this.props.history.push("/requestInfo",{data:val})}>
                        
                        <td>{val.user?val.user.fullname?val.user.fullname.substr(0,12):"-":"-"}</td>
                        <td>{val.city.cityName}</td>
                        <td>{val.category.categoryName}</td>
                        <th>{val.status==="NEW"?allStrings.new:val.status==="ON-PROGRESS"?allStrings.onProgress:val.status==="COMPLETED"?allStrings.completed:allStrings.failed}</th>
                      </tr>
                  ))
                  :
                  <tr style={{height: '300px'}}>
                      <th colSpan="8" className="noData" style={{background:"#fff"}}>
                      <p style={{marginTop:'10rem',textAlign:'center',}}>{allStrings.noData}</p> 
                      </th>
                  </tr>
                }
                </tbody>
              </table>
              <hr></hr>
              <NavLink to="requests">
                <span style={{display:'block',marginBottom:'8px'}}>{allStrings.showAll}</span>
              </NavLink>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="5" md="12" sm="12">
            <div className="theCard" style={{minHeight:'505px'}}>
              <NavLink to="offices">
                <span style={{position: 'absolute'}}>{allStrings.showAll}</span>
              </NavLink>
              <p className="topCard"  style={{color: '#43425D'}}>{allStrings.topOfficesRate}</p>
              <img alt="" width="100%" src={require('../../assets/images/Map.png')}></img>
              <div>
                <span className="rightSpan">{topOfficesRate[0]?this.props.isRTL?topOfficesRate[0].fullname:topOfficesRate[0].fullname.substr(0,12) :""}</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{color:'#54D8FF'}}></span>
                  <span className="spanNum">{topOfficesRate[0]?topOfficesRate[0].rate?topOfficesRate[0].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topOfficesRate[1]?this.props.isRTL?topOfficesRate[1].fullname:topOfficesRate[1].fullname.substr(0,12):"-" }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#5EE2A0'}}></span>
                  <span className="spanNum">{topOfficesRate[1]?topOfficesRate[1].rate?topOfficesRate[1].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topOfficesRate[2]?this.props.isRTL?topOfficesRate[2].fullname:topOfficesRate[2].fullname.substr(0,12):"-"  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FFA177'}}></span>
                  <span className="spanNum">{topOfficesRate[2]?topOfficesRate[2].rate?topOfficesRate[2].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topOfficesRate[3]?this.props.isRTL?topOfficesRate[3].fullname:topOfficesRate[3].fullname.substr(0,12):"-"  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#A3A1FB'}}></span>
                  <span className="spanNum">{topOfficesRate[3]?topOfficesRate[3].rate?topOfficesRate[3].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topOfficesRate[4]?this.props.isRTL?topOfficesRate[4].fullname:topOfficesRate[4].fullname.substr(0,12):"-"  }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FBA69D'}}></span>
                  <span className="spanNum">{topOfficesRate[4]?topOfficesRate[4].rate?topOfficesRate[4].rate:"-":"-"}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topOfficesRate[5]?this.props.isRTL?topOfficesRate[5].fullname:topOfficesRate[5].fullname.substr(0,12):"-" }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FF7CC3'}}></span>
                  <span className="spanNum">{topOfficesRate[5]?topOfficesRate[5].rate?topOfficesRate[5].rate:"-":"-"}</span>
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer> 
      </Spin>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Home)
);
