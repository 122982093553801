/* eslint-disable no-sparse-arrays */
import React from 'react';
import Table from '../../component/table/table'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import { MDBContainer,  MDBRow, MDBCol} from "mdbreact";
import './transaction.css'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import { withRouter,Redirect} from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { CSVLink } from "react-csv";

import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';

const { TreeNode } = TreeSelect;
const { RangePicker } = DatePicker;
let headers = [
  { label: "#", key: "id" },
  { label: "Transaction Num", key: "transactionNum" },
  { label: "Type", key: "type" },
  { label: "Status", key: "status" },
  { label: "Owner", key: "user" },
  { label: "Cost", key: "cost" },
  { label: "Date", key: "date" },
  
];
 
let csvData = [
  
];
class transactions extends React.Component {
  
  state={
    transactions:[],
    transactions2:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    search:null,
    count:0,
    status:null,
    type:null,
    showFilter:false,
    startDate:null,
    endDate:null,
    package:null,
    csvData:[],
  }

  constructor(props){
    super(props)
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }

  componentDidMount(){
    this.getTransactions(1,false)
    this.getTransactionsWithoutPagenation()
    
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    }
  }
  
  delete = (id) => {
    console.log("ID   ",id)
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}transactions/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
     },
    })
    .then(response=>{
      console.log("transaction DELETED")
      l.then(() => message.success(allStrings.done, 2.5) )
      this.getTransactions(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error(allStrings.error, 2.5))
    })
  }
  getTransactions = (page,refresh) =>{
    this.setState({loading:true})
    let {type,status,search,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}transactions?page=${page}`
    if(refresh !== true ){
      let keysToFilter = {type,status,search,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    axios.get(url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      },
     })
    .then(response=>{
       console.log("transactions   ",response.data)
      let data = response.data.data.map((item,index)=>{
      return  [
        item.id,
        item.type?item.type===1?{type:'tag',value:allStrings.buyPackage}:item.type===2?{type:'tag',value:allStrings.addAds}:item.type===3?{type:'tag',value:allStrings.deposit}:{type:'tag',value:allStrings.rent}:"",
        item.status?item.status==="SUCCESS"?{type:'tag',value:allStrings.success}:{type:'tag',value:allStrings.faild}:"",
        item.user?item.user.fullname.substr(0,15)?item.user.fullname.substr(0,15):"":"",
        item.cost?item.cost:"",
        item.tax?item.tax:"5",
        item.totalCost?item.totalCost:item.cost,
        item.dateMillSec?this.props.isRTL?moment(item.dateMillSec).locale('ar').format('ll'):moment(item.dateMillSec).locale('en').format('ll'):"",
        "action"
      ]
      })  
      this.setState({
        loading:false,
        refresh:false,
        transactions:response.data.data,
        transactions2:data,
        pages:response.data.pageCount,
        count:response.data.totalCount
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  getTransactionsWithoutPagenation = (refresh) =>{
    let {type,status,search,startDate,endDate} = this.state
    let url = `${BASE_END_POINT}transactions/withoutPagenation/get?all=true`
    
    if(refresh !== true ){
      let keysToFilter = {type,status,search,startDate,endDate}
      Object.keys(keysToFilter).forEach(key => {
        if (keysToFilter[key] !== null) {
          url += `&${key}=${keysToFilter[key]}`;
        }
      });
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en',
      }
    })
    .then(response=>{
      
        csvData= response.data.data.map(item => {
          return {
            ...{id:item.id},
            ...{transactionNum:item.transactionId?item.transactionId:""},
            ...{type:item.type?item.type===1?allStrings.buyPackage:item.type===2?allStrings.addAds:item.type===3?allStrings.deposit:allStrings.rent:""},
            ...{status:item.status==="SUCCESS"?allStrings.success:allStrings.faild},
            ...{user:item.user.fullname?item.user.fullname:''},
            ...{cost:item.cost?item.cost:""},
            ...{date:item.dateMillSec?this.props.isRTL?moment(item.dateMillSec).locale('ar').format('ll'):moment(item.dateMillSec).locale('en').format('ll'):""}
          };
        });
        this.setState({csvData})
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })
  }

 


  paginationButtons = () =>(
    <CardActions style={{display: 'inline-flex'}}>
        <Button 
        onClick={()=>{
          if(this.state.page!==1){
            this.getTransactions(1)
            this.setState({page:1})
          }
        }}
        className="pagenation" >{allStrings.first}</Button>

        <Button 
        onClick={()=>{
         if(this.state.page>1){
            this.getTransactions(this.state.page-1)
            this.setState({page:this.state.page-1})
         }
        }}
        className="pagenation">{allStrings.prev}</Button>
        
        <Pagination
        onChange={(event,page)=>{
        console.log("page   ",page)
        if(page!==this.state.page){
          this.getTransactions(page)
          this.setState({page:page})
        }
        
        }} 
        defaultPage={1} page={this.state.page} count={this.state.pages} 
        style={{color:`#1D9598 !important`}} />
        
        <Button 
        onClick={()=>{
          if(this.state.page<this.state.pages){
            this.getTransactions(this.state.page+1)
            this.setState({page:this.state.page+1})
          }
        }}
        className="pagenation">{allStrings.next}</Button>

        <Button 
         onClick={()=>{
          if(this.state.page!==this.state.pages){
            this.getTransactions(this.state.pages)
            this.setState({page:this.state.pages})
          }
        }}
        className="pagenation">{allStrings.last}</Button>

    </CardActions>
  )
  onChange = (date, dateString) =>{
    if(this.props.isRTL){
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD"),
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })
      this.setState({
        startDate:moment(dateString[0]).locale('en').format("YYYY-MM-DD") ,
        endDate:moment(dateString[1]).locale('en').format("YYYY-MM-DD")
      })
      console.log(moment(dateString[0]).locale('en').format("YYYY-MM-DD") )

    }
    console.log( dateString);
  }
  render(){
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.permission.pages.includes('TRANSACTIONS')) return <Redirect to='/Home' />

   console.log(this.state.search)
  return (
    <div className="App">
        
      <MDBContainer> 
      <MDBRow>
        <MDBCol md="12">
          <div className="screenTable">
          <Table
            settings = {
              <div>
                <i class="fab fa-searchengin searchIcon" onClick={()=>this.setState({showFilter:!this.state.showFilter})}></i>
                <span 
                style={{zIndex: '1',position: 'relative',padding: '4px',color: '#659FF1',fontSize: '25px',borderRadius:'50%',top:'4px',cursor: 'pointer'}}
                onClick={() => {this.getTransactions(1,false);this.getTransactionsWithoutPagenation(true)}}
                class="material-icons">
                  replay
                </span>
                <CSVLink 
                  data={this.state.csvData} 
                  headers={headers} 
                  filename={"transaction.csv"}
                  style={{padding: '10px',position: 'relative',top: '-5px'}}
                  >
                  <img alt="" src={require('../../assets/images/export-csv.png')} width="28"></img>
                </CSVLink>
              </div>
              
            }
            filter ={
              <div>
                <div className={this.state.showFilter?'showClass':'hideClass'} style={{height: this.state.showFilter?'150px':'0px'}}>
                 
                  <MDBRow>
                    
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                      <label htmlFor="form605" style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.date}</label>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <RangePicker format={"YYYY-MM-DD"} placeholder={[allStrings.startDate,allStrings.endDate]} onChange={this.onChange} />
                      </ConfigProvider>
                    </MDBCol>
                    
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.status}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.status}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.status}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({status:value});
                            }} 
                        
                        >
                            <TreeNode value="SUCCESS" title={allStrings.success} key="SUCCESS" />
                            <TreeNode value="FAILD" title={allStrings.faild} key="FAILD" />
                          
                        </TreeSelect>
                    </MDBCol>
                    <MDBCol xl="4" lg="4" md="4" sm="12">
                        <label style={{textAlign:this.props.isRTL?'right':'left',width:'100%'}}>{allStrings.type}</label>
                        <TreeSelect virtual={false}
                            showSearch={false}
                            value={this.state.type}
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',minHeight:'35px',marginBottom:'1rem'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder={allStrings.type}
                            onChange={(value) => {
                            console.log(value)
                            this.setState({type:value});
                            }} 
                        
                        >
                            <TreeNode value={1} title={allStrings.buyPackage} key={1} />
                            <TreeNode value={2} title={allStrings.addAds} key={2} />
                            <TreeNode value={3} title={allStrings.deposit} key={3} />
                            <TreeNode value={4} title={allStrings.rent} key={4} />
                          
                        </TreeSelect>
                    </MDBCol>
                  </MDBRow>
                  <Button style={{background: '#1D9598',color: '#fff',width: '100%',margin: '32px auto'}} 
                    onClick={()=>{this.getTransactions(1);this.getTransactionsWithoutPagenation()}}><SearchOutlined /> {allStrings.Search}</Button>

                </div>
              </div>
            }
            enableEdit={false}
            title={allStrings.Transactions}
            icon='featured_video'
            data={this.state.transactions}
            data2={this.state.transactions2}
            tableColumns={["#",/*allStrings.transactionNum*/,allStrings.type,allStrings.status,allStrings.owner,allStrings.cost,allStrings.tax,allStrings.totalCost,allStrings.createdAt,allStrings.action]}
            loading={this.state.loading}
            noDelete={true}
            deleteUser={(id)=>{this.delete(id)}}
            path='/transactionInfo'
            actionIndex={5}
            dataCount={this.state.count}
            />
            {this.paginationButtons()}
            
            </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(transactions)
);

